$( document ).ready(function() {
    console.log( "ready!" );
});

$("#contact-form").submit(function (event) {
	console.log( "submit button clicked" );
          event.preventDefault();
          
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
		console.log( "Validation started" );  
		if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          console.log( "Validation error" );
        } else { 
			console.log( "Validation successful" );
			form.classList.add('was-validated-successful');
			// Collect Form Data and submit to PHP
			$("#submit").addClass("disabled");
			var name = $("#name").val();
			var email = $("#email").val();
			var phone = $("#phone").val();
			var message = $("#message").val();
			var data = "name=" + name + "&email=" + email +  "&phone=" + phone + "&message=" + message;
			if (form.checkValidity() === true) {
				$.ajax({
					type: "POST",
					url: "submit.php",
					data: data,
					success: function (response) {
						// Display Feedback for User
						switch (response) {
							case "Sent":
								console.log( "Response: Sent" );
								$("#form-div").html("<div class='alert alert-success' style='padding:10px 30px;'>Ihre Nachricht wurde versendet. Vielen Dank.</div>");
								break;
							case "Failed":
								console.log( "Response: Failed" );
								alert("Something went wrong, please try again.");
								$("#submit").removeClass("disabled");
								break;
						}
					}
				});
			}	
		}
        form.classList.add('was-validated');     
    });
});




